/*!
 * perfect-scrollbar v1.5.0
 * Copyright 2020 Hyunje Jun, MDBootstrap and Contributors
 * Licensed under MIT
 */
.ps__rail-x, .ps__rail-y {
  opacity: 0;
  display: none;
  position: absolute;
}

.ps {
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  overflow: hidden !important;
}

.ps__rail-x {
  height: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  bottom: 0;
}

.ps__rail-y {
  width: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  right: 0;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: #0000;
  display: block;
}

.ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y, .ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y {
  opacity: .6;
}

.ps .ps__rail-x.ps--clicking, .ps .ps__rail-x:focus, .ps .ps__rail-x:hover, .ps .ps__rail-y.ps--clicking, .ps .ps__rail-y:focus, .ps .ps__rail-y:hover {
  opacity: .9;
  background-color: #eee;
}

.ps__thumb-x, .ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  position: absolute;
}

.ps__thumb-x {
  height: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  bottom: 2px;
}

.ps__thumb-y {
  width: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  right: 2px;
}

.ps__rail-x.ps--clicking .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x:hover > .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y:hover > .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  z-index: 2000;
  background: #d72630;
  width: 100%;
  height: 2px;
  position: fixed;
  top: 0;
  right: 100%;
}

.waves-effect {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.waves-effect .waves-ripple {
  opacity: 0;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
  -o-transition-property: -o-transform, opacity;
  -webkit-transition-property: -webkit-transform, opacity;
  pointer-events: none;
  -moz-transition-property: -moz-transform, opacity;
  background: radial-gradient(#0003 0, #0000004d 40%, #0006 50%, #00000080 60%, #fff0 70%);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  transition-property: transform, opacity;
  position: absolute;
  transform: scale(0)translate(0);
}

.waves-effect.waves-light .waves-ripple {
  background: radial-gradient(#fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.waves-effect.waves-classic .waves-ripple {
  background: #0003;
}

.waves-effect.waves-classic.waves-light .waves-ripple {
  background: #fff6;
}

.waves-notransition {
  -o-transition: none !important;
  transition: none !important;
}

.waves-button, .waves-circle {
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%);
}

.waves-button, .waves-button-input, .waves-button:hover, .waves-button:visited {
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  color: inherit;
  text-align: center;
  z-index: 1;
  background-color: #0000;
  border: none;
  outline: 0;
  font-size: 1em;
  line-height: 1em;
  text-decoration: none;
}

.waves-button {
  border-radius: .2em;
  padding: .85em 1.1em;
}

.waves-button-input {
  margin: 0;
  padding: .85em 1.1em;
}

.waves-input-wrapper {
  vertical-align: bottom;
  border-radius: .2em;
}

.waves-input-wrapper.waves-button {
  padding: 0;
}

.waves-input-wrapper .waves-button-input {
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
}

.waves-circle {
  text-align: center;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
}

.waves-float {
  -o-transition: all .3s;
  transition: all .3s;
  box-shadow: 0 1px 1.5px 1px #0000001f;
  -webkit-mask-image: none;
}

.waves-float:active {
  box-shadow: 0 8px 20px 1px #0000004d;
}

.waves-block {
  display: block;
}

.feather, [data-feather] {
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.bootstrap-touchspin .input-group-btn-vertical {
  z-index: 11;
  height: 100%;
  position: absolute;
  right: 0;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  text-align: center;
  width: 2em;
  height: 50%;
  padding: 0;
  line-height: 1;
  position: absolute;
  right: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 4px 0 0;
  top: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 4px;
  bottom: 0;
}
/*# sourceMappingURL=index.bbb9eb0a.css.map */
